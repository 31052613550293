import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import FightClub from "../containers/fight-club";

const FightClublPage = () => (
  <Layout>
    <Seo title="Page two" />
    <FightClub />
  </Layout>
);

export default FightClublPage;
