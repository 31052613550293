import { Link } from "gatsby";
import * as React from "react";
const FightClub = () => (
  <div className="flex flex-wrap overflow-hidden">
    <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-1">
      <img
        className="w-full object-cover"
        src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654843138/training%20center/fight%20club/boxe-session-gym_wp0gq4.webp"
        alt="hua hin muay thai team"
      />
    </div>

    <div className="bg-geraniumlake w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 p-8 lg:px-16 lg:p-0 order-2 ">
      <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold py-12 text-white">
        Fight Club
      </h2>
      <h3 className="text-xl md:text-2xl lg:text-3xl font-light py-4 text-white">
        Elite warrior training
      </h3>
      <p className="text-xl md:text-3xl lg:text-xl py-6 text-white leading-loose md:leading-loose lg:leading-loose uppercase lg:pr-20">
        Our world wide renowned Elite fight clubs are conveniently located in
        two great destinations. In Bangkok we offer Muay Thai trainings as group
        classes or private training. We also offer Brazilian JiuJitsu and
        Western boxing. In Huahin we offer "accomodation and training" packages
        as we function as Muay Thai camp with 8 new bungalows with AC, smart tv
        and ​
      </p>
      <a
        className="font-medium tracking-wide text-gray-100 uppercase transition-colors duration-200 hover:text-redelite"
        href="https://www.elitefightclub.com/hua-hin"
        rel="noopener noreferrer follow"
        target="_blank"
      >
        Click here to visit the Fight Clubs website
      </a>
    </div>
    <div className="bg-white pt-16 md:pt-20 md:pb-16 p-8 lg:p-20 w-full overflow-hidden  lg:w-1/2 xl:w-1/2 order-4 lg:order-3">
      <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold py-12 text-geraniumlake">
        Lets fight!
      </h2>
      <h3 className="text-xl md:text-2xl lg:text-3xl font-light py-4 text-black">
        Join the Elite Fighting Team
      </h3>
      <p className="text-xl md:text-3xl lg:text-xl py-6 text-blackleading-loose md:leading-loose lg:leading-loose uppercase lg:pr-20">
        Elite Fight Club in Bangkok is not only a vibrant and motivating gym
        built to improve your fighting condition and technique. We are a
        community of warriors, that all strive towards greater achievements both
        mentally and physically.
      </p>
      <p className="text-xl md:text-3xl lg:text-xl py-6 text-black leading-loose md:leading-loose lg:leading-loose uppercase lg:pr-20">
        A meeting point and a place for growth personally and as a team. Come
        down and meet us and see for yourself what the Elite community is about.{" "}
      </p>
    </div>
    <div className="w-full overflow-hidden md:w-full lg:w-1/2 xl:w-1/2 order-3 lg:order-4">
      {" "}
      <img
        className="w-full object-cover"
        src="https://res.cloudinary.com/elite-training-center/image/upload/q_auto,f_auto,c_scale,w_auto/v1654843138/training%20center/fight%20club/coach-fight-club_ogtkal.webp"
        alt="exercises gym training bangkok muay thai team"
      />
    </div>
  </div>
);

export default FightClub;
